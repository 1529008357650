import { Box, HStack, VStack, Flex, Text, Button } from "@chakra-ui/react";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { AnyAction } from 'redux';

import { IAddressDetails, IProductInfo, IVendorOrderDetails, IVendorOrderDetailsExt, TVendorOrderStatus } from "../common/types";
import { RootState } from "../store";
import { approveOrder, cancelOrder, cancelOrderAndSubscription, loadOrderDetails, updateOrderDetails } from "../store/action-creators";
import { formatDateShort, getProductQuantity, updateProductsQuantity } from "../common/utils";
import Loading from "../components/Loading";
import FormActionButton from "../components/FormActionButton";
import AddressEditable from "../components/AddressEditable";
import FieldEditable from "../components/FieldEdtiable";
import { QuestionIcon } from "@chakra-ui/icons";
import { orderStatusColor } from "../common/utils";

type TTabView = 'GeneralInfo' | 'OrderFulfillment' | 'InternalNotes';
export type TAddressType = 'shippingAddress' | 'billingAddress';
export interface IFormFields {
  shippingAddress: IAddressDetails;
  billingAddress: IAddressDetails;
  fleetQuantity: number;
  products: IProductInfo[];
}

const OrderDetails = (props: Props) => {
  const { orderId, orders, loading, error, loadOrderDetails, updateOrderDetails, approveOrder, cancelOrder, cancelSubscription } = props;

  const orderDetails: IVendorOrderDetailsExt = orders[orderId] as IVendorOrderDetailsExt;
  const customerName = (orderDetails?.firstName + ' ' || '') + (orderDetails?.lastName || '');
  const orderStatus = orderDetails?.status as TVendorOrderStatus;
  const approvingOrder = orderDetails?.approvingOrder;
  const cancellingOrder = orderDetails?.cancellingOrder;
  const cancellingSubscription = orderDetails?.cancellingSubscription;
  const editModeOn = orderStatus === "New" && !approvingOrder && !cancellingOrder;

  const [activeTab, setActiveTab] = useState<TTabView>("GeneralInfo");
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<IFormFields>({} as IFormFields);

  const resetFormData = useCallback(() => {
    setFormFields({
      shippingAddress: orderDetails?.shippingAddress,
      billingAddress: orderDetails?.billingAddress,
      fleetQuantity: getProductQuantity(orderDetails?.products, 'FLEET'),
      products: [],
    });
  }, [orderDetails]);

  // API fetch order details
  useEffect(() => {
    loadOrderDetails(orderId);
  }, [loadOrderDetails, orderId]);

  // initialize editable form data 
  useEffect(() => {
    resetFormData();
  }, [orderDetails, resetFormData]);

  const onCancelEdit = () => {
    resetFormData();
    setEditEnabled(false);
  }

  const onSaveEdit = () => {
    const updatedProducts = updateProductsQuantity(orderDetails?.products, 'FLEET', formFields.fleetQuantity);
    formFields.products = updatedProducts;
    updateOrderDetails(orderId, formFields);
    setEditEnabled(false);
  }

  const handleFormChange = (e: FormEvent) => {
    e.preventDefault();
    const el = e.target as HTMLInputElement;
    const { value, name, type} = el;
    const addressType: TAddressType = el.getAttribute("data-address-type") as TAddressType;

    if (type === "number" && value && isNaN(parseFloat(value))) return;

    if (addressType) {
      setFormFields(prevState => ({
          ...prevState,
          [addressType]: { 
            ...prevState[addressType],
            [name]: value,
          }
      }));
    } else {
      setFormFields(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection="row"
      gap="35px"
    >
      <Box 
        minH="345px"
        minW="900px"
        flexGrow={1}
        className="container"
      >
      {loading || error  
        ? <Loading error={error} />
        : <VStack
            fontSize={14}
            textAlign="left" 
          >
            <HStack
              w="100%"
              h="105px"
              paddingY='20px'
              paddingX='30px'
              alignItems="center"
              flexWrap="wrap"
              spacing="10px"
            >
              <Flex className='info-stack' flex="2 1 auto" >
                <h5>MOJIO ORDER NUMBER</h5>
                <Text fontWeight='700' color='black'>{orderId}</Text>
              </Flex>
              <Flex className='info-stack' flex="2 1 auto" >
                <h5>ORGANIZATION ID</h5>
                <Text>{orderDetails?.organizationId}</Text>
              </Flex>
              <Flex className='info-stack' flex="1 1 auto" >
                <h5>ORDER ORIGIN</h5>
                <Text>{orderDetails?.vendorName}</Text>
              </Flex>
            </HStack>
            <Flex 
              w="100%" 
              paddingX="30px"
              borderTop="2px solid"
              borderBottom="2px solid"
              borderColor="gray.300"
              bg="gray.100" 
              gap="16px"
              justifyContent="space-between"
            >
              <Box
                className={`tab-btn ${activeTab === "GeneralInfo" ? 'active' : ''}`}
                onClick={() => setActiveTab("GeneralInfo" as TTabView)}
              >
                General Information
              </Box>
              {editModeOn &&
                <>
                  {!editEnabled ?
                    <FormActionButton 
                      formAction="edit"
                      action={() => setEditEnabled(true)}
                      label="Edit"
                    /> : 
                    <HStack gap={6}>
                      <FormActionButton 
                        formAction="save"
                        action={onSaveEdit}
                        label="Save"
                      /> 
                      <FormActionButton 
                        formAction="cancel"
                        action={onCancelEdit}
                        label="Cancel"
                      /> 
                    </HStack>
                  }
                </>
              }
            </Flex>
            {orderDetails 
              ? <HStack 
                w="100%"
                paddingX="30px"
                paddingY="20px"
                alignItems="flex-start" 
                flexWrap="wrap"
                gap="25px"
              >
                <Flex className='info-stack' flex="1 1 220px" gap={1}>
                  <h5>CUSTOMER DETAILS</h5>
                  <Text><b>Company: </b>{orderDetails?.companyName}</Text>
                  <Text><b>Name: </b>{customerName}</Text>
                  <Text><b>Email: </b>{orderDetails?.email}</Text>
                  <Text><b>Phone: </b>{orderDetails?.phone}</Text>
                  <Text><b>User ID: </b>{orderDetails?.userId}</Text>
                </Flex>
                <Flex className='info-stack' flex="1 1 220px" >
                  <h5>BILLING ADDRESS</h5>
                  <AddressEditable 
                    editEnabled={false}
                    addressType='billingAddress'
                    address={formFields['billingAddress']}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex className='info-stack' flex="1 1 220px" >
                  <h5>SHIPPING ADDRESS</h5>
                  <AddressEditable 
                    editEnabled={editEnabled}
                    addressType='shippingAddress'
                    address={formFields['shippingAddress']}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex className='info-stack' flex="1 1 220px" gap={1}>
                  <h5>ORDER DETAILS</h5>
                  <Text><b>Ordered:</b> {formatDateShort(orderDetails?.orderDate)}</Text>
                  {orderDetails?.externalOrderId &&
                    <Text><b>Kore Order ID:</b> {orderDetails?.externalOrderId}</Text>
                  }
                  <Text><b>Vendor Id:</b> {orderDetails?.vendorId}</Text>
                  <Text><b>Vendor Name:</b> {orderDetails?.vendorName}</Text>
                  <FieldEditable 
                    editEnabled={editEnabled} 
                    value={formFields.fleetQuantity}
                    label="Quantity" 
                    name="fleetQuantity" 
                    type="number"
                    onChange={handleFormChange} 
                  />
                </Flex>
              </HStack>
              : <VStack>
                  <QuestionIcon marginTop={25} w={30} h={30} color="gray.300" />
                  <Text>Order Id: <b>{orderId}</b> cannot be found</Text>
                </VStack>
            }
          </VStack>
      }
      </Box>
      <VStack 
        flex="0 0 270px"
        minH="120px"
        padding="20px 30px"
        justifyContent="space-between"
        alignItems="center"
        className="container"
        spacing="25px"
      >
        <HStack w="100%" justifyContent="space-between">
          <Text fontSize="18px">Order Status</Text>
          <Box
              fontSize="14px"
              fontWeight="500"
              padding="1px 6px"
              bg={orderStatusColor(orderStatus)}
              rounded="3px"
              textColor="white"
              textTransform="uppercase"
            >
              {orderStatus}          
          </Box>
        </HStack>
        {orderStatus === "New" && 
          <>
            <Box w="100%" borderBottom="1px solid" borderColor="gray.300" />
            {(!cancellingOrder || !cancellingSubscription) &&
              <Button
                isLoading={approvingOrder}
                loadingText="Approving"
                onClick={() => approveOrder(orderId)}
                fontSize="14px"
                padding="8px 20px"
                fontWeight="500"
                width="100%"
                colorScheme="blue"
                rounded="4px"
                textColor="white"
                _hover={{ bg: 'blue.300' }}
              >
                Approve Order
              </Button>
            }
            {(!approvingOrder || !cancellingSubscription) &&
              <Button
                isLoading={cancellingOrder}
                loadingText="Cancelling"
                onClick={() => cancelOrder(orderId)}
                fontSize="14px"
                padding="8px 20px"
                fontWeight="500"
                width="100%"
                bg="pink.500"
                rounded="4px"
                textColor="white"
                _hover={{ bg: 'pink.300' }}
              >
                Cancel Order 
              </Button>
            }
            <Box w="100%" borderBottom="1px solid" borderColor="gray.300" />
            {(!approvingOrder || !cancellingOrder) &&
              <Button
                isLoading={cancellingSubscription}
                loadingText="Cancelling"
                onClick={() => cancelSubscription(orderId, orderDetails.userId)}
                fontSize="14px"
                padding="8px 20px"
                fontWeight="500"
                width="100%"
                bg="red.400"
                rounded="4px"
                textColor="white"
                _hover={{ bg: 'red.200' }}
              >
                Cancel Subscription
              </Button>
            }
          </>
        }
      </VStack>
    </Flex>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {
  orderId: string
}

const mapStateToProps = (state: RootState) => ({ 
  orders: state.orders.entities,
  loading: state.orders.loadingDetails,
  loaded: state.orders.loadedDetails,
  error: state.orders.error,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
  loadOrderDetails: (orderId: string) => dispatch(loadOrderDetails(orderId)),
  updateOrderDetails: ((orderId: string, updates: Partial<IVendorOrderDetails>) => dispatch(updateOrderDetails(orderId, updates))),
  approveOrder: (orderId: string) => dispatch(approveOrder(orderId)),
  cancelOrder: (orderId: string) => dispatch(cancelOrder(orderId)),
  cancelSubscription: (orderId: string, userId: string) => dispatch(cancelOrderAndSubscription(orderId, userId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(OrderDetails);