import { ActionType } from "../action-types/index";
import { IToastMessage } from "../../common/types";

interface NewToastMessage {
  type: ActionType.NEW_TOAST_MESSAGE,
  payload: { toast: IToastMessage }
}
interface ClearToastMessages {
  type: ActionType.CLEAR_TOAST_MESSAGES,
  payload: {}
}

export type ToastAction = NewToastMessage | ClearToastMessages;

export const newToastAction = (toast: IToastMessage): NewToastMessage => ({
  type: ActionType.NEW_TOAST_MESSAGE,
  payload: { toast }
});

export const clearToastsAction = (): ClearToastMessages => ({
  type: ActionType.CLEAR_TOAST_MESSAGES,
  payload: {}
});