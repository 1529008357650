import { IToastMessage } from '../../common/types';
import { ActionType } from '../action-types';
import { Action } from "../actions/index"

interface IToastState extends IToastMessage {
  timestamp: Date;
};

const initialState = {} as IToastState;

export const reducer = (state: IToastState = initialState, action: Action): IToastState => {
  switch (action.type) {
    case ActionType.NEW_TOAST_MESSAGE:
      const { toast }: { toast: IToastMessage } = action.payload;
      return {
        ...state,
        ...toast,
        timestamp: new Date(),
      }
    case ActionType.CLEAR_TOAST_MESSAGES:
      return {} as IToastState;
    default:
      return state;
  }
}

export default reducer;