import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React, { KeyboardEvent, useState } from "react";
import { OrdersAction } from "../store/actions";
import { TOrderFilter } from "../store/reducers/ordersReducer";

function SearchOrdersModal({isOpen, onClose, setUserIdSearch, setOrderFilter}: Props): JSX.Element {
  const initialRef = React.useRef(null)
  const [userId, setUserId] = useState<string>("");

  const onSearchOrderByUserId = () => {
    setUserIdSearch(userId);
    setOrderFilter('All');
    onModalClose();
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchOrderByUserId();
    }
  }

  const onModalClose = () => {
    setUserId("");
    onClose();
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onModalClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Find by User Id</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>User Id</FormLabel>
            <Input 
              ref={initialRef} 
              value={userId} 
              onChange={e => setUserId(e.target.value)} 
              placeholder='User Id' 
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button 
            colorScheme='blue' 
            mr={3} 
            fontSize="14px" 
            disabled={!userId}
            onClick={onSearchOrderByUserId}
          >
            Search
          </Button>
          <Button onClick={onModalClose} fontSize="14px">Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  setUserIdSearch: (userId: string) => OrdersAction;
  setOrderFilter: (orderFilter: TOrderFilter) => OrdersAction;
}

export default SearchOrdersModal;
