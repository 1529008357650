import { JwtPayloadExt } from "../../common/types";
import { ActionType } from "../action-types/index"

interface SetAuthStart {
  type: ActionType.SET_AUTH_START,
  payload: {}
}
interface SetAuthSuccess {
  type: ActionType.SET_AUTH_SUCCESS,
  payload: { jwt: JwtPayloadExt, accessToken: string }
}

interface SetAuthFail {
  type: ActionType.SET_AUTH_FAIL,
  payload: { error: string }
}
interface ClearAuthAction {
  type: ActionType.CLEAR_AUTH,
  payload: {}
}

export type AuthAction = SetAuthStart | SetAuthSuccess| SetAuthFail | ClearAuthAction;

export const setAuthStartAction = (): SetAuthStart => ({
  type: ActionType.SET_AUTH_START,
  payload: {}
});

export const setAuthSuccessAction = (jwt: JwtPayloadExt, accessToken: string): SetAuthSuccess => ({
  type: ActionType.SET_AUTH_SUCCESS,
  payload: { jwt, accessToken }
});

export const setAuthFailAction = (error: string): SetAuthFail => ({
  type: ActionType.SET_AUTH_FAIL,
  payload: { error }
});

export const clearAuthAction = (): ClearAuthAction => ({
  type: ActionType.CLEAR_AUTH,
  payload: {}
});