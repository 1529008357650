import React from "react";
import { HStack, Box, ComponentWithAs } from "@chakra-ui/react";
import { EditIcon, CheckIcon, CloseIcon, IconProps } from "@chakra-ui/icons";

export type TFormActions = 'save' | 'edit' | 'cancel';
const IconMapping: { [key in TFormActions]: { icon: ComponentWithAs<"svg", IconProps>, size: string }} = {
  save: { icon: CheckIcon, size: "14px" },
  edit: { icon: EditIcon, size: "15px" }, 
  cancel: { icon: CloseIcon, size: "11px" },
}

const FormActionButton = ({ formAction, action, label }: { formAction: TFormActions, action: Function, label: string }): JSX.Element => {
  const { icon: Icon, size } = IconMapping[formAction];
  return (
    <HStack
      onClick={() => action()}
      _hover={{ 
      textDecoration: "underline",
      cursor: "pointer", 
    }}>
      <Box>{label}</Box>
      <Icon 
        w={size}
        h={size}
        color="blue.500"
      />
    </HStack>
  );
}

export default FormActionButton;
