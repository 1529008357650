import "./styles/App.css";
import { Route } from "react-router-dom";

import Header from "./containers/NavBar";
import Home from "./components/Home";
import Orders from "./components/Orders";
import AuthLayer from "./containers/AuthLayer";
import ScreenContainer from "./components/ScreenContainer";
import ToastMessage from "./containers/ToastMessage";

const App = (): JSX.Element => {
  return (
    <AuthLayer>
      <div className="App" >
        <div className="page">
          <div className="content">
            <Header />
            <ScreenContainer>
              <Route path="/" exact={true} component={Home} />
              <Route
                path={["/orders", "/orders/:orderId"]} 
                exact={true}
                component={Orders}
              />
            </ScreenContainer>
            <ToastMessage />
          </div>
        </div>
      </div>
    </AuthLayer>
  );
};

export default App;
