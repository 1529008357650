import { JwtPayloadExt } from "../../common/types";
import { ActionType } from "../action-types/index"

interface SetUserAction {
  type: ActionType.SET_USER,
  payload: { jwt: JwtPayloadExt }
}

interface SetUserAuthState {
  type: ActionType.SET_USER_AUTH,
  payload: { isAuthed: boolean }
}

interface SignOutUser {
  type: ActionType.SIGN_OUT_USER,
  payload: {}
}

export type UserAction = SetUserAction | SetUserAuthState | SignOutUser;

export const setUserAction = (jwt: JwtPayloadExt): SetUserAction => ({
  type: ActionType.SET_USER,
  payload: { jwt }
});

export const setUserAuthStateAction = (isAuthed: boolean): SetUserAuthState => ({
  type: ActionType.SET_USER_AUTH,
  payload: { isAuthed }
})

export const signOutUserAction = (): SignOutUser => ({
  type: ActionType.SIGN_OUT_USER,
  payload: {}
});