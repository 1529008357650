import axios from "axios";
import { IPatchVendorOrder, TVendorOrderStatus } from "../common/types";
import { generateAlphanumeric } from '../common/utils';
import { store } from "../store";

const DEFAULT_VENDOR_ORDERS = 100;

// URI generator

export const goToAuth = () => {
  const MOJIO_VERSION = '2018-09-01', SCOPE = 'admin', NONCE = generateAlphanumeric(36);
  const IDENTITY_SERVER = process.env.REACT_APP_MOJIO_IDENTITY_SERVER
  const CLIENT_ID = process.env.REACT_APP_MOJIO_CLIENT_ID;
  const BASE_AUTH_URL = `${IDENTITY_SERVER}/account/signinmojio`;
  const encodedCurrentHref: string = encodeURI(`http://${process.env.REACT_APP_DOMAIN}/force-orders/callback`); 
  const primaryReturnUrl =
    '/connect/authorize/callback?' +
    `response_type=token&client_id=${CLIENT_ID}&redirect_uri=${encodedCurrentHref}?urls.primaryName=${MOJIO_VERSION}&scope=${SCOPE}&state=${NONCE}&realm=ordersadmin`;
  const authorizeUrl = new URL(BASE_AUTH_URL);
  authorizeUrl.searchParams.set('returnurl', primaryReturnUrl);
  window.location.href = decodeURI(authorizeUrl.toString());
}

// Vendor API endpoints

const getAccessToken = (): string => {
  const state = store.getState();
  return state.auth.accessToken;
}

const apiVendorConfig = (params?: Object) => {
  const accessToken = getAccessToken();
  if (!accessToken) return;

  const MOJIO_VERSION = '2021-07-01';
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "x-mojio-version": MOJIO_VERSION,
    },
    params,
  };

  return config;
}

export const getVendorOrders = async (params: {orderStatus: TVendorOrderStatus, top?: number, skip?: number}) => {
  const { orderStatus, top = DEFAULT_VENDOR_ORDERS, skip } = params;
  const url = `${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/orders?orderStatus=${orderStatus}`;
  const config = apiVendorConfig({ top, skip })

  try {
    return (await axios.get(url, config)).data;
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
}

export const getVendorOrdersDetails = async (params: { orderStatus?: TVendorOrderStatus, userId?: string, top?: number, skip?: number }) => {
  const { orderStatus, userId, top = DEFAULT_VENDOR_ORDERS, skip } = params;
  const url = new URL(`${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/orders/details`);
  if (orderStatus) url.searchParams.append('orderStatus', orderStatus);
  if (userId) url.searchParams.append('userId', userId);

  const config = apiVendorConfig({ top, skip })

  try {
    return (await axios.get(url.toString(), config)).data;
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
}

export const getVendorOrderExtDetails = async (params: { orderId: string }) => {
  const { orderId } = params; 
  const url = `${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/orders/${orderId}/extendeddetails`;
  const config = apiVendorConfig()
  try {
    return (await axios.get(url, config)).data;
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
}

export const patchVendorOrderDetails = async (params: { orderId: string, patchOrder: IPatchVendorOrder }) => {
  const { orderId, patchOrder } = params; 
  const url = `${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/orders/${orderId}`;
  const config = apiVendorConfig()

  try {
    const res = await axios.patch(url, patchOrder, config);
    if (res.status !== 200) throw new Error(); 
    return res.data;    
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
}

export const postVendorOrderApproval = async (params: { orderIds: string[] }) => {
  const { orderIds } = params;
  const url = `${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/orders/approvals`;
  const body = { orderIds }
  const config = apiVendorConfig();

  try {
    return (await axios.post(url, body, config)).data;
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
}

export const deleteVendorOrder = async (params: { orderId: string }) => {
  const { orderId } = params;
  const url = `${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/orders/${orderId}`;
  const config = apiVendorConfig();

  try {
    return (await axios.delete(url, config)).data;
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
}

export const cancelCustomerSubscription = async (params: { userId: string }) => {
  const { userId } = params;
  const url = `${process.env.REACT_APP_FORCE_ORDER_ENDPOINT}/Vendors/customers/${userId}/cancellations`;
  const config = apiVendorConfig();

  try {
    return (await axios.post(url, {}, config)).data;
  } catch(err) {
    console.warn(err);
    throw new Error();
  }
} 

export const recordToLogger = (msg: string) => {
  fetch("http://localhost:8080/logger", {
    method: "POST",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: msg,
  })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.warn(err));
}
