import { Dispatch } from "redux";
import { deleteVendorOrder, getVendorOrderExtDetails, getVendorOrdersDetails, patchVendorOrderDetails, postVendorOrderApproval, cancelCustomerSubscription } from "../../api/APIUtils";
import { GetStoredToken, TokenDecoded, IsTokenValid, RemoveStoredToken } from "../../api/TokenStorage";

import { IUpdatedOrdersDto, IAuthToken, IVendorOrderDetailsExt, IVendorOrdersDto, JwtPayloadExt, TVendorOrderStatus, IVendorOrderDetails, IPatchVendorOrder, IPatchAddress, IAddressDetails } from "../../common/types";
import { 
  Action, setUserAction, setAuthSuccessAction, setUserAuthStateAction, clearAuthAction, signOutUserAction, loadOrdersSuccessAction, loadOrdersStartAction, loadOrdersFailAction, setAuthStartAction, setAuthFailAction, 
  loadOrderDetailsStartAction, loadOrderDetailsSuccessAction, loadOrderDetailsFailAction, approveOrdersStartAction, approveOrdersSuccessAction, approveOrdersFailAction, cancelOrderStartAction, cancelOrderFailAction, cancelOrderSuccessAction, 
  updateOrderStartAction, updateOrderSuccessAction, updateOrderFailAction, newToastAction, setActiveOrderFilterAction, cancelSubscriptionStartAction, cancelSubscriptionSuccessAction, cancelSubscriptionFailAction 
} from "../actions";

export const initAuthAndUser = () => (dispatch: Dispatch<Action>) => {
  dispatch(setAuthStartAction());
  try {
    const accessToken: string = GetStoredToken();
    const jwtPayload: JwtPayloadExt | null = TokenDecoded(accessToken);

    if (!jwtPayload) return;
    dispatch(setUserAction(jwtPayload));
    dispatch(setAuthSuccessAction(jwtPayload, accessToken));
  } catch(err) {
    let message = 'Unknown error'
    if (err instanceof Error) message = err.message;
    dispatch(setAuthFailAction(message));
  }
}

export const setUserIsAuthed = (authToken: IAuthToken) => (dispatch: Dispatch<Action>) => {
  const tokenIsValid = IsTokenValid(authToken);
  dispatch(setUserAuthStateAction(tokenIsValid));
}

export const signOutUser = () => (dispatch: Dispatch<Action>) => {
  dispatch(clearAuthAction());
  dispatch(signOutUserAction());
  RemoveStoredToken();
}

export const loadOrders = (orderStatus?: TVendorOrderStatus, userId?: string) => async (dispatch: Dispatch<Action>) => {
  // let skip = page * ordersPerPage;
  // let top = ordersPerPage;
  try {
    if (!orderStatus) dispatch(setActiveOrderFilterAction('All'));
    dispatch(loadOrdersStartAction());
    const res = await getVendorOrdersDetails({ orderStatus, userId });
    const orderData = res as IVendorOrdersDto;
    dispatch(loadOrdersSuccessAction(orderData));
  } catch(err) { 
    let message = 'Unknown Error'
    if (err instanceof Error) message = err.message;
    dispatch(loadOrdersFailAction(message));
  }
}

export const loadOrderDetails = (orderId: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch(loadOrderDetailsStartAction());
    const res = await getVendorOrderExtDetails({ orderId });
    const orderData = res as IVendorOrderDetailsExt;
    dispatch(loadOrderDetailsSuccessAction(orderData));
  } catch(err) { 
    let message = 'Unknown Error'
    if (err instanceof Error) message = err.message;
    dispatch(loadOrderDetailsFailAction(message));
  }
}

export const updateOrderDetails = (orderId: string, updates: Partial<IVendorOrderDetails>) => async (dispatch: Dispatch<Action>) => {
  const mapAddressToPatch = (updates?: IAddressDetails): IPatchAddress => {
    if (!updates) return {} as IPatchAddress;
    return {
      firstName: updates?.firstName,
      lastName: updates?.lastName,
      company: updates?.company || updates?.lastName,
      phone: updates?.phoneNumber,
      street1: updates?.address1,
      street2: updates?.address2,
      city: updates?.city,
      region: updates?.region,
      postalCode: updates?.postalCode,
      country: updates?.country,
    }
  }
  
  const patchOrder = {
    shippingAddress: mapAddressToPatch(updates?.shippingAddress),
    billingAddress: mapAddressToPatch(updates?.billingAddress),
    products: updates.products,
  } as IPatchVendorOrder;

  try {
    dispatch(updateOrderStartAction(orderId, updates));
    await patchVendorOrderDetails({ orderId, patchOrder });
    dispatch(updateOrderSuccessAction(orderId, updates));
    dispatch(newToastAction({ title: 'Update Successful', message: 'Order Details have been updated.', status: 'success' }));
  } catch(err) {
    let message = 'Unknown error occurred updating order details.'
    if (err instanceof Error) message = err.message;
    dispatch(updateOrderFailAction(orderId, message)); 
    dispatch(newToastAction({ title: 'Update Failed', message, status: 'warning' })); 
  }
}

export const approveOrder = (orderId: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch(approveOrdersStartAction([orderId]));
    const res = await postVendorOrderApproval({ orderIds: [orderId] });
    if (!Array.isArray(res) || !res.length) throw new Error('Error Approving Order');
    const orderApproved = res[0] as IUpdatedOrdersDto;
    if (orderApproved.status.toLowerCase() !== 'success') {
      throw new Error(orderApproved.message);
    }
    dispatch(approveOrdersSuccessAction([orderApproved.orderId]));
    dispatch(newToastAction({ title: 'Approve Order Successful', message: 'Order successfully approved.', status: 'success' }));
  } catch(err) {
    let message = 'Unknown error occurred approving order.';
    if (err instanceof Error) message = err.message;
    dispatch(approveOrdersFailAction([orderId]));  
    dispatch(newToastAction({ title: 'Approve Order Failed', message, status: 'warning' }));
  }
}

export const cancelOrder = (orderId: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch(cancelOrderStartAction(orderId));
    const res = await deleteVendorOrder({ orderId });
    if (res.status.toLowerCase() !== 'cancelled') {
      throw new Error(res.message);
    }
    dispatch(cancelOrderSuccessAction(orderId));
    dispatch(newToastAction({ title: 'Cancel Order Successful', message: 'Order successfully cancelled.', status: 'success' }));
  } catch(err) {
    let message = 'Unknown error occurred cancelling order.'
    if (err instanceof Error) message = err.message;
    dispatch(cancelOrderFailAction(orderId, message));  
    dispatch(newToastAction({ title: 'Cancel Order Failed', message, status: 'warning' }));
  }
}

export const cancelOrderAndSubscription = (orderId: string, userId: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch(cancelSubscriptionStartAction(orderId));
    await cancelCustomerSubscription({ userId });
    const cancelOrderRes = await deleteVendorOrder({ orderId }); 
    if (cancelOrderRes.status.toLowerCase() !== 'cancelled') {
      throw new Error('Failed to cancel order:', cancelOrderRes.message);
    }
    dispatch(cancelSubscriptionSuccessAction(orderId));
    dispatch(newToastAction({ title: 'Cancel Subscription Successful', message: 'Order and subscription successfully cancelled.', status: 'success' }));
  } catch(err) {
    let message = 'Unknown error occurred cancelling order and subscription.';
    if (err instanceof Error) message = err.message;
    dispatch(cancelSubscriptionFailAction(orderId, message));  
    dispatch(newToastAction({ title: 'Cancel Subscription Failed', message, status: 'warning' }));
  }
} 