import { Box, Button, Text, HStack, Flex, VStack } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { useHistory } from "react-router-dom";
import { IVendorOrderDetails, TVendorOrderStatus } from "../common/types";
import { formatDateShort, getProductQuantity, orderStatusColor } from "../common/utils";

const OrderCard = ({ order }: Props): JSX.Element => {
  const history = useHistory();
  const { 
    orderId,
    externalOrderId,
    firstName,
    lastName,
    email,
    phone,
    shippingAddress,
    products,
    status,
    orderDate 
  } = order;

  const fleetQuantity = getProductQuantity(products, 'FLEET');

  return (
    <Box margin="24px" className="container">
      <HStack 
        minH="120px" 
        alignItems="center" 
        justifyContent="space-between" 
        spacing='10px'
        paddingY='14px'
        paddingX='28px'
        fontSize={14}
        textAlign="left" 
        >
        <Flex className='info-stack' flex="0 0 25%" >
          <h5>MOJIO ORDER NUMBER</h5>
          <Text fontWeight='700' color='black'>{orderId}</Text>
          {externalOrderId &&
            <Text marginTop="18px"><b>Kore Order ID:</b> {externalOrderId}</Text>
          }
        </Flex>
        <Flex className='info-stack' flex="0 0 17%">
          <h5>CUSTOMER DETAILS</h5>
          <Text>{firstName} {lastName}</Text>
          <Text>{email}</Text>
          <Text>{phone}</Text>
        </Flex>
        <Flex className='info-stack' flex="0 0 17%">
          <h5>SHIPPING ADDRESS</h5>
          {shippingAddress ? ( 
            <Text>
              {shippingAddress.address1}<br/>
              {shippingAddress.city}, {shippingAddress.region}&nbsp;{shippingAddress.postalCode}
            </Text>
          ):(
            <Text>--</Text>
          )}
        </Flex>
        <Flex className='info-stack' flex="0 0 14%" gap={1}>
          <h5>ORDER DETAILS</h5>
          <Text><b>Date:</b> {formatDateShort(orderDate)}</Text>
          <Text><b>Quantity:</b> {fleetQuantity}</Text>
        </Flex>
        <Flex className='info-stack' flex="0 0 12%">
          <h5>ORDER STATUS</h5>
          <Box
            fontSize="14px"
            fontWeight="500"
            padding="1px 6px"
            bg={orderStatusColor(status as TVendorOrderStatus)}
            rounded="3px"
            textColor="white"
            textTransform="uppercase"
          >
            {status}
          </Box>
        </Flex>
        <VStack alignItems='center' justifyContent='center' flex="0 0 12%">
          <Button
            rightIcon={<ArrowForwardIcon />} variant='outline'
            onClick={() => {
              history.push(`/orders/${orderId}`);
            }}
            fontSize="14px"
          >
            View More
          </Button>
        </VStack>
      </HStack>
    </Box>
  );
}

interface Props {
  order: IVendorOrderDetails;
}

export default OrderCard;
