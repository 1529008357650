import { IVendorOrderDetails, IVendorOrderDetailsExt, IVendorOrdersDto } from "../../common/types";
import { ActionType } from "../action-types/index"
import { TOrderFilter } from "../reducers/ordersReducer";

interface LoadOrdersStart {
  type: ActionType.LOAD_ORDERS_START,
  payload: {}
}
interface LoadOrdersSuccess {
  type: ActionType.LOAD_ORDERS_SUCCESS,
  payload: { dto: IVendorOrdersDto }
}
interface LoadOrdersFail {
  type: ActionType.LOAD_ORDERS_FAIL,
  payload: { error: string }
}
interface LoadOrderDetailsStart {
  type: ActionType.LOAD_ORDER_DETAILS_START,
  payload: {}
}
interface LoadOrderDetailsSuccess {
  type: ActionType.LOAD_ORDER_DETAILS_SUCCESS,
  payload: { dto: IVendorOrderDetailsExt }
}
interface LoadOrderDetailsFail {
  type: ActionType.LOAD_ORDER_DETAILS_FAIL,
  payload: { error: string }
}
interface ApproveOrdersStart {
  type: ActionType.APPROVE_ORDERS_START,
  payload: { orderIds: string[] }
}
interface ApproveOrdersSuccess {
  type: ActionType.APPROVE_ORDERS_SUCCESS,
  payload: { orderIds: string[] }
}
interface ApproveOrdersFail {
  type: ActionType.APPROVE_ORDERS_FAIL,
  payload: { orderIds: string[] }
}
interface CancelOrderStart {
  type: ActionType.CANCEL_ORDER_START,
  payload: { orderId: string }
}
interface CancelOrderSuccess {
  type: ActionType.CANCEL_ORDER_SUCCESS,
  payload: { orderId: string }
}
interface CancelOrderFail {
  type: ActionType.CANCEL_ORDER_FAIL,
  payload: { orderId: string, error: string }
}
interface CancelSubscriptionStart {
  type: ActionType.CANCEL_SUBSCRIPTION_START,
  payload: { orderId: string }
}
interface CancelSubscriptionSuccess {
  type: ActionType.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: { orderId: string }
}
interface CancelSubscriptionFail {
  type: ActionType.CANCEL_SUBSCRIPTION_FAIL,
  payload: { orderId: string, error: string }
}
interface UpdateOrderStart {
  type: ActionType.UPDATE_ORDER_START,
  payload: { orderId: string, updates: Partial<IVendorOrderDetails> }
}
interface UpdateOrderSuccess {
  type: ActionType.UPDATE_ORDER_SUCCESS,
  payload: { orderId: string, updates: Partial<IVendorOrderDetails> }
}
interface UpdateOrderFail {
  type: ActionType.UPDATE_ORDER_FAIL,
  payload: { orderId: string, error: string }
}
interface SetActiveOrderFilter {
  type: ActionType.SET_ACTIVE_ORDER_FILTER,
  payload: { orderFilter: TOrderFilter }
}
interface SetActivePage {
  type: ActionType.SET_ACTIVE_PAGE,
  payload: { page: number }
}
interface SetActiveUserId {
  type: ActionType.SET_ACTIVE_USER_ID,
  payload: { userId: string }
}

export type OrdersAction = LoadOrdersSuccess | LoadOrdersStart | LoadOrdersFail | LoadOrderDetailsStart | LoadOrderDetailsSuccess | LoadOrderDetailsFail | 
ApproveOrdersStart | ApproveOrdersSuccess | ApproveOrdersFail | CancelOrderStart | CancelOrderSuccess | CancelOrderFail | CancelSubscriptionStart | CancelSubscriptionSuccess | CancelSubscriptionFail | 
SetActiveOrderFilter | SetActivePage | SetActiveUserId | UpdateOrderStart | UpdateOrderSuccess | UpdateOrderFail; 

export const loadOrdersStartAction = (): LoadOrdersStart => ({
  type: ActionType.LOAD_ORDERS_START,
  payload: {}
});

export const loadOrdersSuccessAction = (dto: IVendorOrdersDto): LoadOrdersSuccess => ({
  type: ActionType.LOAD_ORDERS_SUCCESS,
  payload: { dto }
});

export const loadOrdersFailAction = (error: string): LoadOrdersFail => ({
  type: ActionType.LOAD_ORDERS_FAIL,
  payload: { error }
});

export const loadOrderDetailsStartAction = (): LoadOrderDetailsStart => ({
  type: ActionType.LOAD_ORDER_DETAILS_START,
  payload: {}
});

export const loadOrderDetailsSuccessAction = (dto: IVendorOrderDetailsExt): LoadOrderDetailsSuccess => ({
  type: ActionType.LOAD_ORDER_DETAILS_SUCCESS,
  payload: { dto }
});

export const loadOrderDetailsFailAction = (error: string): LoadOrderDetailsFail => ({
  type: ActionType.LOAD_ORDER_DETAILS_FAIL,
  payload: { error }
});

export const approveOrdersStartAction = (orderIds: string[]): ApproveOrdersStart => ({
  type: ActionType.APPROVE_ORDERS_START,
  payload: { orderIds }
});

export const approveOrdersSuccessAction = (orderIds: string[]): ApproveOrdersSuccess => ({
  type: ActionType.APPROVE_ORDERS_SUCCESS,
  payload: { orderIds }
});

export const approveOrdersFailAction = (orderIds: string[]): ApproveOrdersFail => ({
  type: ActionType.APPROVE_ORDERS_FAIL,
  payload: { orderIds }
});

export const cancelOrderStartAction = (orderId: string): CancelOrderStart => ({
  type: ActionType.CANCEL_ORDER_START,
  payload: { orderId }
});

export const cancelOrderSuccessAction = (orderId: string): CancelOrderSuccess => ({
  type: ActionType.CANCEL_ORDER_SUCCESS,
  payload: { orderId }
});

export const cancelOrderFailAction = (orderId: string, error: string): CancelOrderFail => ({
  type: ActionType.CANCEL_ORDER_FAIL,
  payload: { orderId, error }
});

export const cancelSubscriptionStartAction = (orderId: string): CancelSubscriptionStart => ({
  type: ActionType.CANCEL_SUBSCRIPTION_START,
  payload: { orderId }
});

export const cancelSubscriptionSuccessAction = (orderId: string): CancelSubscriptionSuccess => ({
  type: ActionType.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: { orderId }
});

export const cancelSubscriptionFailAction = (orderId: string, error: string): CancelSubscriptionFail => ({
  type: ActionType.CANCEL_SUBSCRIPTION_FAIL,
  payload: { orderId, error }
});


export const updateOrderStartAction = (orderId: string, updates: Partial<IVendorOrderDetails>): UpdateOrderStart => ({
  type: ActionType.UPDATE_ORDER_START,
  payload: { orderId, updates }
});

export const updateOrderSuccessAction = (orderId: string, updates: Partial<IVendorOrderDetails>): UpdateOrderSuccess => ({
  type: ActionType.UPDATE_ORDER_SUCCESS,
  payload: { orderId, updates }
});

export const updateOrderFailAction = (orderId: string, error: string): UpdateOrderFail => ({
  type: ActionType.UPDATE_ORDER_FAIL,
  payload: { orderId, error }
});

export const setActiveOrderFilterAction = (orderFilter: TOrderFilter): SetActiveOrderFilter => ({
  type: ActionType.SET_ACTIVE_ORDER_FILTER,
  payload: { orderFilter }
})

export const setActivePageAction = (page: number): SetActivePage => ({
  type: ActionType.SET_ACTIVE_PAGE,
  payload: { page }
})

export const setActiveUserIdAction = (userId: string): SetActiveUserId => ({
  type: ActionType.SET_ACTIVE_USER_ID,
  payload: { userId }
})