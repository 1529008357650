import React, { FormEvent } from "react";
import { VStack, Text, HStack, Input } from "@chakra-ui/react";
import { TAddressType } from "../containers/OrderDetails";
import { IAddressDetails } from "../common/types";

const ViewAddressInfo = ({address}: { address: IAddressDetails }) => {
  if (!address) return (<>--</>);
  return (
    <>
      <Text>{address?.address1}</Text>
      {address?.address2 &&
        <Text>{address?.address2}</Text>
      }
      <Text>{address?.city} {address?.region}, {address?.postalCode}</Text>
    </>
  );
}

const EditAddressInfo = ({address, addressType, onChange}: { address: IAddressDetails, addressType: TAddressType, onChange: (e: FormEvent) => void }) => {
  return (
    <VStack alignItems="flex-start" w="100%" maxW="400px">
      <HStack w="100%">
        <Text><b>Address 1:</b></Text>
        <Input h={27} name="address1" value={address?.address1} data-address-type={addressType} onChange={onChange} ></Input>
      </HStack>
      <HStack w="100%">
        <Text><b>Address 2:</b></Text>
        <Input h={27} name="address2" value={address?.address2} data-address-type={addressType} onChange={onChange} ></Input>
      </HStack>
      <HStack w="100%">
        <Text><b>City:</b></Text>
        <Input h={27} name="city" value={address?.city} data-address-type={addressType} onChange={onChange}></Input>
      </HStack>
      <HStack w="100%">
        <Text><b>State:</b></Text>
        <Input h={27} name="region" data-address-type={addressType} value={address?.region} onChange={onChange}></Input>
      </HStack>
      <HStack w="100%">
        <Text flexShrink={0}><b>Zip Code:</b></Text>
        <Input h={27} name="postalCode" data-address-type={addressType} value={address?.postalCode} onChange={onChange}></Input>
      </HStack>
    </VStack>
  );
}

const AddressForm = ({ editEnabled = false, addressType, address, onChange }: { editEnabled: boolean, addressType: TAddressType, address: IAddressDetails, onChange: (e: FormEvent) => void }): JSX.Element => (
  <>
    {editEnabled ? 
      <EditAddressInfo address={address} addressType={addressType} onChange={onChange} /> :
      <ViewAddressInfo address={address} />
    }
  </>
);

export default AddressForm;
