export enum ActionType {
  SET_AUTH_START = "SET_AUTH_START",
  SET_AUTH_SUCCESS = "SET_AUTH_SUCCESS",
  SET_AUTH_FAIL = "SET_AUTH_FAIL",
  CLEAR_AUTH = "CLEAR_AUTH",
  SET_USER = "SET_USER",
  SET_USER_AUTH = "SET_USER_AUTH",
  SIGN_OUT_USER = "SIGN_OUT_USER",
  LOAD_ORDERS_START = "LOAD_ORDERS_START",
  LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS",
  LOAD_ORDERS_FAIL = "LOAD_ORDERS_FAIL",
  LOAD_ORDER_DETAILS_START = "LOAD_ORDER_DETAILS_START",
  LOAD_ORDER_DETAILS_SUCCESS = "LOAD_ORDER_DETAILS_SUCCESS",
  LOAD_ORDER_DETAILS_FAIL = "LOAD_ORDER_DETAILS_FAIL",
  APPROVE_ORDERS_START = "APPROVE_ORDERS_START",
  APPROVE_ORDERS_SUCCESS = "APPROVE_ORDERS_SUCCESS",
  APPROVE_ORDERS_FAIL = "APPROVE_ORDERS_FAIL",
  CANCEL_ORDER_START = "CANCEL_ORDER_START",
  CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL",
  CANCEL_SUBSCRIPTION_START = "CANCEL_SUBSCRIPTION_START",
  CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL",
  SET_ACTIVE_ORDER_FILTER = "SET_ACTIVE_ORDER_FILTER",
  SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE",
  SET_ACTIVE_USER_ID = "SET_ACTIVE_USER_ID",
  UPDATE_ORDER_START = "UPDATE_ORDER_DETAILS_START",
  UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_DETAILS_SUCCESS",
  UPDATE_ORDER_FAIL = "UPDATE_ORDER_DETAILS_FAIL",
  NEW_TOAST_MESSAGE = "NEW_TOAST_MESSAGE",
  CLEAR_TOAST_MESSAGES = "CLEAR_TOAST_MESSAGES",
}