import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "../store";

const ToastMessage = (props: Props): JSX.Element => {
  const { title, message, status, timestamp } = props;
  const toast = useToast();
  
  useEffect(() => {
    if (timestamp) {
      toast({ 
        title, 
        description: message, 
        status,
        duration: 5000,
        isClosable: true,
        position: 'bottom-right'
      })
    }
  }, [title, message, status, timestamp, toast]);

  return <></>;
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {}

const mapStateToProps = (state: RootState) => ({ 
  title: state.toast.title,
  message: state.toast.message,
  status: state.toast.status,
  timestamp: state.toast.timestamp,
});
const connector = connect(mapStateToProps)

export default connector(ToastMessage);
