import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { connect, ConnectedProps } from "react-redux";
import { useEffect, useRef } from "react";
import { AnyAction } from 'redux';
import { ThunkDispatch } from "redux-thunk";
import { CloseIcon, InfoIcon, SearchIcon } from "@chakra-ui/icons";

import { RootState } from "../store";
import { IVendorOrderDetails, TVendorOrder, TVendorOrderStatus } from "../common/types";
import { loadOrders } from "../store/action-creators";
import { TOrderFilter } from "../store/reducers/ordersReducer";
import { ORDER_STATUS_LABELS } from "../components/Orders";
import Loading from "../components/Loading";
import OrderCard from "../components/OrderCard";
import PaginationMenu from "../components/PaginationMenu";
import { setActiveOrderFilterAction, setActivePageAction, setActiveUserIdAction } from "../store/actions";
import SearchOrdersModal from "../components/SearchOrdersModal";

const OrdersList = (props: Props): JSX.Element => {
  const { 
    orderFilter, userIdSearch, page, totalNumOrders, allOrders, loading, loaded, error, 
    setOrderFilter, setUserIdSearch, setPage, loadOrders 
  } = props;

  const ordersPerPage = 30;
  const orders: TVendorOrder[] = Object.values(allOrders).slice(page * ordersPerPage, (page + 1) * ordersPerPage);
  const listRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const orderStatus = ORDER_STATUS_LABELS[orderFilter]?.status[0];
    loadOrders({ orderStatus, userId: userIdSearch }); 
  }, [orderFilter, loadOrders, userIdSearch])

  const selectOrderFilter = (filter: TOrderFilter) => {
    setPage(0);
    setOrderFilter(filter);
    setUserIdSearch("");
  }

  const scrollToTop = () => {
    listRef.current?.scrollTo({ 
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
  }

  const displayOrders = () => {
    if (loading || error) {
      return <Loading error={error} />
    } else if (orders.length > 0) {
      return orders.map((order, i) => 
        <OrderCard
          key={i}
          order={order as IVendorOrderDetails}
        />
      );
    } else {
      return (
        <Flex 
          w="100%"
          h="100%"
          minH="inherit"
          alignItems="center"
          justifyContent="center"
        >
          <VStack>
            <InfoIcon 
              w={30}
              h={30}
              color="gray.300"
            />
            <Text>No Orders found.</Text>
          </VStack>
        </Flex>
      )
    }
  }

  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <SearchOrdersModal setUserIdSearch={setUserIdSearch} setOrderFilter={setOrderFilter} isOpen={isOpen} onClose={onClose} />
      <Box 
        w="100%"  
        className="container"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            textColor="black" 
            background="gray.600" 
            paddingY="11px" 
            paddingX="24px" 
          >
            <HStack 
              position="sticky"
              spacing="20px" 
              top="0"
              borderRadius="3px 3px 0 0"
              zIndex="1"
            >
              {Object.entries(ORDER_STATUS_LABELS).map(([filter, orderStatus]) => {
                const { label, colorScheme } = orderStatus;
                return (
                  <Box
                    key={filter}
                    onClick={() => selectOrderFilter(filter as TOrderFilter)}
                    fontSize="14px"
                    fontWeight="500"
                    padding="5px 15px"
                    bg={orderFilter === filter ? colorScheme: ""}
                    rounded="3px"
                    textColor="white"
                    transition="200ms"
                    _hover={{ 
                      textColor: "white",
                      background: colorScheme, 
                      cursor: "pointer", 
                    }}>
                    { label }
                  </Box>
                )}
              )}
            </HStack>
            <HStack gap="12px">
              {userIdSearch && 
                <Box
                  background="white"
                  borderRadius="13px"
                  height="30px"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  padding="0 14px"
                  gap="13px"
                >
                  <Text color="gray.500">User ID: {userIdSearch}</Text>
                  <Icon as={CloseIcon} w="10px" h="10px" cursor="pointer" onClick={() => selectOrderFilter('InQueue')}></Icon>
                </Box>
              }
              <Button 
                leftIcon={<SearchIcon />} 
                variant="solid"
                size="sm"
                onClick={onOpen}
                >
                  Find Order
              </Button>
            </HStack>
          </Box>
          <Box 
            ref={listRef}
            overflow="auto" 
            minH="370px"
            h="calc(100vh - 140px - 58px - 85px)"> 
            {/* (Screen - Header - Status Bar - Bottom) */}
              {displayOrders()}
          </Box>
      </Box>
      <PaginationMenu 
        ordersLoaded={loaded}
        activePage={page}
        prevPage={() => setPage(page - 1)}
        nextPage={() => setPage(page + 1)}
        ordersPerPage={ordersPerPage}
        totalNumOrders={totalNumOrders}
        scrollToTop={scrollToTop}
      />
    </Flex>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {}

const mapStateToProps = (state: RootState) => ({ 
  orderFilter: state.orders.activeState.orderFilter, 
  userIdSearch: state.orders.activeState.userId,
  page: state.orders.activeState.page,
  allOrders: state.orders.entities,
  totalNumOrders: state.orders.results,
  loading: state.orders.loading,
  loaded: state.orders.loaded,
  error: state.orders.error,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
  loadOrders: ({orderStatus, userId}: {orderStatus?: TVendorOrderStatus, userId?: string}) => dispatch(loadOrders(orderStatus, userId)),
  setOrderFilter: (orderFilter: TOrderFilter) => dispatch(setActiveOrderFilterAction(orderFilter)),
  setUserIdSearch: (userId: string) => dispatch(setActiveUserIdAction(userId)),
  setPage: (page: number) => dispatch(setActivePageAction(page))
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(OrdersList);
