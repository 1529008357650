import { ORDER_STATUS_LABELS } from "../components/Orders";
import { IProductInfo, TProductCode, TVendorOrderStatus } from "./types";

export const generateAlphanumeric = (length: number, randomString=""): string => {
  randomString += Math.random().toString(20).substr(2, length);
  if (randomString.length > length) return randomString.slice(0, length);
  return generateAlphanumeric(length, randomString);
};

export const formatDateShort = (date: Date): string => {
  if (!date) return "";
  const dateString = new Date(date).toDateString();
  const dateStringArr = dateString.split(' ');
  dateStringArr.shift();
  dateStringArr[1] += ',';
  return dateStringArr.join(' ');
}

export const orderStatusColor = (orderStatus: TVendorOrderStatus): string => {
  const orderStatusLabel = Object.values(ORDER_STATUS_LABELS).find(({ status }) =>
   (status.includes(orderStatus))
  );
  const color = orderStatusLabel?.colorScheme ?? '';
  return color;
}

export const getProductQuantity = (products: IProductInfo[] = [], productType: TProductCode): number => {
  const product = products.find(product => product.inventorySku?.startsWith(productType) || product.productCode?.startsWith(productType));
  return product?.quantity || 0;
}

export const updateProductsQuantity = (products: IProductInfo[] = [], productType: TProductCode, quantity: number): IProductInfo[] => {
  const updatedProducts = [...products];
  const index = products.findIndex(product => product.inventorySku?.startsWith(productType) || product.productCode?.startsWith(productType));
  
  if (updatedProducts[index]) {
    updatedProducts[index].quantity = Number(quantity);
    if (!('inventorySku' in updatedProducts[index])) {
      updatedProducts[index].inventorySku = updatedProducts[index].productCode;
    }
  }
  return updatedProducts;
}