import React, { FormEvent } from "react";
import { Text, HStack, Input } from "@chakra-ui/react";

const FieldEditable = ({ editEnabled = false, label, name, value, onChange, type = "text" }: Props): JSX.Element => {
  return (
    <HStack w="100%">
      <Text><b>{label}</b></Text>
      {editEnabled ? 
        <Input maxW="240px" h={27} name={name} value={value} onChange={onChange} type={type} ></Input>:
        <Text>{value ?? "--"}</Text>
      }
    </HStack>
  );
};

interface Props {
  editEnabled: boolean, 
  label: string, 
  name: string, 
  value: string | number, 
  onChange: (e: FormEvent) => void, 
  type: "text" | "number" 
}

export default FieldEditable;
